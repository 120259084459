import React from "react"
import { Link } from 'gatsby';
import Img from "gatsby-image";

const SubsidiaryCard = ({node}) => 

    (
        <div className="project-card p-4 w-full sm:w-1/2 lg:w-1/3 xl:w-1/4">
            <div className="p-6 border-shadow h-full">
                {node.name === "Hausman" ? 
                <img className="h-24 mx-auto my-8" src={node.logo.file.url} />
                :
                <img className="h-16 mx-auto my-12" src={node.logo.file.url} />
                }
                <div className="text-sm">
                    <p className="font-aktiv font-bold">{node.name}</p>
                    <div className="text-sm nested-html"
                       dangerouslySetInnerHTML={{ __html: node.address.childMarkdownRemark.html }}
                    />                    

                    <hr className="px-8" />

                    <div className="py-4">
                        <div className="flex mb-2">
                            <p className="w-12">Tel:</p>
                            <p className="">{node.telephone}</p>
                        </div>
                        <div className="flex mb-2">
                            <p className="w-12">Fax:</p>
                            <p className="">{node.fax}</p>
                        </div>
                        <div className="flex">
                            <p className="w-12">Email:</p>
                            <p className=""><a href={`mailto:${node.email}`}>{node.email}</a></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

export default SubsidiaryCard;
