import React from "react"
import Img from "gatsby-image";
import { Link } from "gatsby";

const LocationCard = ({node}) => (
    <div className="md:flex flex-initial card large-card border-shadow md:h-64 mb-12">

        <div className="md:w-5/12">
            <iframe 
                className="h-full w-full"
                  frameBorder="0" 
                  scrolling="no" 
                  marginHeight="0" 
                  marginWidth="0" 
                  loading="lazy"
                  src={`//www.google.com/maps/embed/v1/place?key=AIzaSyDm3URouV6VdjBQWzd45CYpciSht1LZagA&q=${node.googleMapsLocation.lat},${node.googleMapsLocation.lon}`}
                 >
             </iframe>
        </div>

        <div className="md:w-7/12 p-8">
            <h3 className="font-aktiv font-bold text-1-5 mb-6">{node.title}</h3>
            <div className="md:flex">
                <div className="md:w-1/2 mb-4 md:mb-0 nested-html"
                     dangerouslySetInnerHTML={{ __html: node.address.childMarkdownRemark.html }}
                />                    

                <div className="md:w-1/2">
                    {node.telephone && <p><span className="mr-6">Tel:</span>{node.telephone}</p>}
                    {node.fax && <p><span className="mr-6">Fax:</span>{node.fax}</p>}
                    {node.emailAddress && <p><span className="mr-3">Email:</span>{node.emailAddress}</p>}
                </div>
            </div>
        </div>

    </div>

)
export default LocationCard
