import React from "react";
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import LocationCard from "../components/location-card";
import SubsidiaryCard from "../components/subsidiarycard";

const Locations = ({ data: { hero, locations, subsidiaries } }) => {

    hero.buttons = (
        <div className="mt-8">
            <Link to="/contact">
                <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight mr-6">
                    contact form
                </button>
            </Link>
        </div>
    )
    
    hero.subsectionName = 'Locations';

    return (

        <Layout hero={hero}>
            <div className="container mx-auto mb-16 px-4">
                    {locations.edges.map(({node}) => (
                        <LocationCard key={node.id} node={node}/>
                    ))}
            </div>
            <div className="container mx-auto mb-16">
                <h2 className="font-aktiv tracking-tight text-xl font-bold text-mane-blue px-4">Our Subsidiaries</h2>
                <div className="flex flex-wrap">
                    {subsidiaries.edges.map(({node}) => (
                        <SubsidiaryCard key={node.id} node={node}/>
                    ))}
                </div>
            </div>
        </Layout>

    )};

export const query = graphql`
    query LocationsPage {
      hero: contentfulLocationPage (slug: {eq: "location"}) {
        heroBackgroundImage {
          resize(width: 1440) {
           src
          }
        }
        heroHeading: heroHeadingOne
        heroText {
            childMarkdownRemark {
                html
            }
        }
      }
      locations: allContentfulLocations(sort: {fields: createdAt}) {
        edges {
            node {
                id
                title
                address {
                    childMarkdownRemark {
                        html
                    }
                }
                telephone
                fax
                emailAddress
                googleMapsLocation{
                    lat
                    lon
                }
            }
        }
      }
      subsidiaries: allContentfulSubsidiaries (sort: { fields: createdAt}) {
        edges {
           node {
            id
            name
            address {
                childMarkdownRemark {
                    html
                }
            }
            logo {
               file {
                   url
               }
            }
            telephone
            fax
            email
           }
        }
      }
    }
`;


export default Locations;
